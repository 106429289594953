import { Routes } from "@angular/router";
import { WelcomeComponent } from "./pages/welcome/welcome.component";
import { VisitedBeforeComponent } from "./components/visited-before/visited-before.component";
import { ClinicSetGuard } from "./guards/clinic-set.guard";
import { LastBookedComponent } from "./pages/last-booked/last-booked.component";
import { LoginComponent } from "./components/login/login.component";
import { UnknownClinicComponent } from "./components/unknown-clinic/unknown-clinic.component";
import { AppointmentComponent } from "./pages/appointment/appointment.component";
import { AuthGuard } from "./guards/auth.guard";
import { StaffComponent } from './pages/staff/staff.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { PatientDetailsComponent } from './patients/details/details.component';
import { InitializeComponent } from './components/initialize/initalize.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ConfirmLeaveGuard } from './guards/can-deactivate.guard';
import { TreatmentComponent } from './pages/treatment/treatment.component';
import { CompletedBookingComponent } from './pages/completed-booking/completed-booking.component';
import { TreatmentCatsComponent } from './pages/treatment-cats/treatment-cats.component';
import { IcalDownloadComponent } from "./pages/ical-download/ical-download.component";

export const routes: Routes = [
    { path: 'clinic/:clinicName', component: InitializeComponent },
    { path: 'registration', component: RegistrationComponent },
    { path: 'staff/:treatmentCat/:treatmentIdx', component: StaffComponent, canActivate: [AuthGuard] },
    { path: 'confirm', component: ConfirmationComponent, canActivate: [AuthGuard], canDeactivate: [ConfirmLeaveGuard] },
    { path: 'details', component: PatientDetailsComponent, canActivate: [AuthGuard] },
    { path: 'treatment', component: TreatmentComponent, canActivate: [AuthGuard] },
    { path: 'payment-link/:clinicName/:appointment', component: InitializeComponent },
    { path: 'subscription/:clinicName/:subscription', component: InitializeComponent },
    { path: 'c/:clinicName/:ical', component: InitializeComponent },
    { path: 'calendar/:ical', component: IcalDownloadComponent },
    { path: 'payment',  
        loadChildren: () =>
        import("./pages/payment/stripe/payment-stripe.module").then((m) => m.PaymentStripeModule), 
    },
    { path: 'complete', component: CompletedBookingComponent },
    { path: 'treatment-cat', component: TreatmentCatsComponent },
    {
        path: '',
        component: WelcomeComponent,
        children: [{
            path: '',
            component: VisitedBeforeComponent,
            canActivate: [ClinicSetGuard]
        },
        {
            path: 'rebook',
            component: LastBookedComponent,
            canActivate: [ClinicSetGuard]
        },
        {
            path: 'login',
            component: LoginComponent,
            canActivate: [ClinicSetGuard]
        },
        {
            path: 'unknown',
            component: UnknownClinicComponent
        },
        {
            path: 'appointment',
            component: AppointmentComponent,
            canActivate: [AuthGuard]
        },
        
        ]
    },
    {
        path: '**', pathMatch: 'full',
        redirectTo: "/"
    }];

    //  https://multiclinic.local.a2it.dev:4200/c/demo/679766d650049033b0d1aab