
import { Component, Inject, Input, OnInit } from '@angular/core';
import { select, Store } from "@ngrx/store";
import * as fromRoot from '../../store/reducers';
import * as fromBooking from "../../store/actions/booking";
import * as fromDate from "../../store/actions/date";
import { BookingService } from 'src/app/services/booking.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';
import { StaffService } from 'src/app/services/staff.service';
import * as _moment from 'moment';
import { Treatment } from 'src/app/models/treatment';
import { AppointmentRequest } from 'src/app/models/booking';
import { Staff } from 'src/app/models/staff';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
 
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd DD MMM YYYY',
    monthYearLabel: 'ddd DD MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'ddd DD MMMM YYYY',
  },
};


@Component({
    selector: 'app-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss', '../../pages/welcome/welcome.component.scss'],
    standalone: false
})
export class AppointmentComponent {
  @Input() data: any;
  readonly datePicker = new FormControl(new Date());


  public treatmentCategory: any = null;
  public treatmentType: any = null;
  public selectedTreatment: any = null;
  public selectedType: any = null;
  public predefinedType: boolean = false;
  public duration: number = 15;
  public startTime: Date;
  public endTime: Date;
  public nearestTimes: any[] = [];
  public staffIdxs: number[];
  public selectedNearestTime: any;
  public selectedNearestStaff: any;
  public nearestStart = DateTime.now();
  public todayDate = DateTime.now();
  public nearestTimesRun: boolean = false;
  public daysTracking: Date[] = [];
  public quickSearched: boolean = false;
  public staff: Staff[];
  public selectedStaff: Staff | undefined;
  // public booking: any;
  public availabilities: any;
  public today = new Date();
  public foundAvailability = false;

  public treatment: Treatment | undefined;

  constructor(
    private store: Store<fromRoot.State>,
    private appointmentService: AppointmentService,
    public bookingService: BookingService,
    private staffService: StaffService,
    private loading: LoadingService,
    private router: Router) { }

  ngOnInit(): void {
    this.store.select(fromRoot.getAvailabilities).subscribe((availabilities: any) => this.availabilities = availabilities);
    this.store.select(fromRoot.getDate).subscribe(selectedDate => {
      if (selectedDate) {
        console.log('[date] update ', selectedDate);
        this.nearestStart = selectedDate;
        this.datePicker.setValue(selectedDate);
      }
    });
  
    this.staffService.allStaff.subscribe(res=>{
      this.staff = res;
      this.init();
    });
    this.store.dispatch(fromDate.setSelectedDate({ selectedDate: DateTime.now() }));

  }
  
  init(){
    this.store.select(fromRoot.getTreatmentAndStaff).subscribe(info => {
      console.log("[info]", info);
      this.staffIdxs = info.staff;
      if (info.staff.length==1){
        this.selectedStaff = this.findStaffMember(info.staff[0]);
      }
      this.treatment = info.treatment
      this.getNearestAppointment();
    })

  }
dateInput(event){
  console.log("[date] input event");
 // this.selectDate(this.validateDate(event.value)); //fires all the time on typing
}


dateChange(event){
  console.log("[date] change event");
  this.selectDate(this.validateDate(event.value));
}

validateDate(event: DateTime):DateTime{
  console.log("[date] event", event);
  if (!event){
    return DateTime.now();  
  }
  const diff = event.diff(DateTime.now(), 'days').toObject().days || 0;
  console.log("[date] diff=", diff);
  if (diff <= -1 || diff >90){
    return DateTime.now();  
  }

  return event;
}


// this handles selecting the date and dispatching via ngrx to set the selected date as the current state
  public selectDate(event: DateTime) {
    console.log("[date] event", event);
    this.nearestStart = event;
    this.store.dispatch(fromDate.setSelectedDate({ selectedDate: this.nearestStart }));
    this.getNearestAppointment();
  }

  public nextDay() {
    this.nearestStart = this.nearestStart.plus({days: 1})
    this.store.dispatch(fromDate.setSelectedDate({ selectedDate: this.nearestStart }));
    this.getNearestAppointment();
  }

  public previousDay() {
    this.nearestStart = this.nearestStart.minus({days: 1});
    if (this.nearestStart.toMillis() < DateTime.now().toMillis()){
      this.nearestStart = DateTime.now();
    }
    this.store.dispatch(fromDate.setSelectedDate({ selectedDate: this.nearestStart }));
    this.getNearestAppointment();
  }

  public validateNearestStart(){
    if (this.nearestStart.toFormat('yyyy-MM-dd') == DateTime.now().toFormat('yyyy-MM-dd')){ //is it today?
      this.nearestStart = DateTime.now();
    }else{
      this.nearestStart = this.nearestStart.set({hour: 1, minute: 0, second: 0});
    }
  }

  public morePreviousDays(): boolean {
    return (!this.appointmentService.sameDay(this.nearestStart, this.todayDate) && this.daysTracking.length > 0 && !this.quickSearched);
  }

  public getNearestAppointment(previousPressed: boolean = false, quickSearched: boolean = false): any {
    const MAX_DAYS = 0;
    this.validateNearestStart();
    console.log("[apps] getNEarest");
    const start = this.nearestStart;
    //const end = new Date((new Date(this.nearestStart.getTime()).setDate(new Date(this.nearestStart.getTime()).getDate() + 6)));
    const end = this.nearestStart.set({hour: 23});
  


    const payload: any = {
      selectVals: this.staffIdxs,
      startTime: start,
      endTime: end
    };
 
    this.loading.start();
    console.log("[apps] staff?", payload);
    this.bookingService.getAppointment(payload).subscribe((appointments: any) => {
      console.log("[apps]", appointments);
      if (!this.treatment){return;}
      this.nearestTimes = this.appointmentService.getNearestAppointment(this.treatment, this.availabilities, appointments, payload);

      // jump on the day with the nearest availability once, so back next and datfilter work correctly
      if (!this.foundAvailability && this.nearestTimes && this.nearestTimes.length == 0) {
        
        if ((+this.nearestStart - +(new Date())) / (1000 * 60 * 60 * 24) > MAX_DAYS) {
          console.log(`No appoointments in the next ${MAX_DAYS} days. Stop!`);
          this.loading.stop();
          return
        }
        this.nextDay()
        return
      } else {
        this.foundAvailability = true
      }

      console.log("[apps] nearest", this.nearestTimes);
      this.nearestTimesRun = true;

      this.quickSearched = quickSearched;
      this.store.select(fromRoot.getDate).subscribe(selectedDate => {
        if (selectedDate) {
          console.log(selectedDate, 'why')
          this.nearestStart = selectedDate;
        }
      });

      /*
      if (!this.appointmentService.sameDay(this.nearestStart, this.todayDate) && !previousPressed) {
        this.daysTracking.push(new Date(this.nearestStart.getTime()));
      }

      if (this.nearestTimes.length && !this.appointmentService.sameDay(this.nearestStart, this.nearestTimes[0][0].requestedStart)) {
        this.nearestStart = new Date(new Date(this.nearestTimes[0][0].requestedStart.getTime()).setHours(0, 0, 0, 0));
      }

      console.log("[apps]", this.daysTracking, this.nearestStart);
      */
      this.loading.stop();
    })
  }

  public nearestTimeSelected(nearestTime: any): void {
    this.selectedNearestTime = nearestTime;
    this.onConfirm()
  }

  public nearestStaffSelected(nearestStaff: any): void {
    this.selectedNearestStaff = nearestStaff;
    this.onConfirm()
  }

  public clearTimes(): void {
    this.nearestTimesRun = false;
    this.selectedType = null;
    this.nearestTimes = [];
    this.clearTimeAndStaffSelection();
  }

  public clearTimeAndStaffSelection(): void {
    this.selectedNearestTime = [];
    this.selectedNearestStaff = null;
  }

  public findStaffMember(idx: number) {
    return this.staff.find(s => (s.idx == idx));
  }

  public onConfirm(): void {
    if (!this.treatment){return;}
    const payload: AppointmentRequest = {
      treatmentType: this.treatment.name,
      treatmentIdx: this.treatment.idx,
      duration: this.treatment.defaultDuration,
      price: this.treatment.defaultPrice,
      startTime: this.selectedNearestTime.startTime,
      staffIdx: this.selectedNearestTime.staffIdx
    }

    this.store.dispatch(fromBooking.RequestAppointment({ payload: payload }));

    this.router.navigate(["/confirm"]);
  }

  
  back(){
    this.router.navigate(['treatment-cat']);
  }

}

